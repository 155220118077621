body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  overflow-x:hidden;
  -webkit-overflow-scrolling: touch;
  min-width: 300px;


}

p:hover, li:hover, h1:hover, h2:hover, h3:hover {
  cursor: default;
}

a {
  text-decoration:none;
}


/* ------- HEADER ------ */

.header {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0.5rem;
}

.logo-container {
  flex: 1;
}

.logo-container img {
  max-width: 60%;
  margin-top: 0.2em;
}

.links-container {
  flex: 2;
  display: none;
  justify-content: space-between;
}

.link {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  transition: transform 0.2s ease-in-out;
}

.link.clicked {
  transform: scale(0.9);
}

.button-container {
  flex: 1;
  justify-content: space-between;
  text-align: right;
}

.child {
  display: flex;
  justify-content: right;

  transition: all 0.3s ease-in-out;
}

.login-button {
  display: flex;
  justify-content: center;
  background-color: rgb(255, 120, 1);
  color: rgb(253,253,251);
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0.3em;
  margin-left: 0.4em;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration:none;

  border-bottom: 0.18em solid #1ba0f220;
  border-bottom-style: groove;
  transition: all 0.3s ease-in-out;
}

.login-button:hover {
  font-size: 1.3rem;
}

@media only screen and (min-width: 950px) {
  .links-container {
    display: flex;
    justify-content: space-between;
  }
}

.langBtn {
  width: 1.5em;
  height: 1.5em;
  border: 0;
  margin: 0 0.1em;

  font-size: 1.3em;
  border-radius: 0.3em;
  transition: all 0.3s ease-in-out;
}

.langBtn:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.4);
  transform: scale(1.05);
}

.langBtn span {
  display: flex;
  margin: auto;
  padding: 0;
}


@media only screen and (max-width: 500px) {
  .button-container {
    flex: 2;
  }
  .logo-container img {
    max-width: 100%;
  }

  .login-button {
    padding: 0.6rem 1.5rem;
  }
}


/* ------------------------ */

/* --------- HERO --------------- */

.hero {
  background-color: transparent;
  padding: 2rem 0;
}

.hero-container {
  max-width: 1400px;
  padding: 0rem 4rem; 

  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.hero-content {
  flex-basis: 50%;
  margin-bottom: 2rem;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-text {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  margin-right: 5rem;
}

.hero-button {
  background-color: rgb(255, 120, 1);
  color: rgb(253,253,251);
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.3em;
  font-size: 1.2rem;
  cursor: pointer;

  border-bottom: 0.18em solid #1ba0f220;
  border-bottom-style: groove;
  transition: all 0.3s ease-in-out;
}

.hero-button:hover {
  font-size: 1.3rem;
}

.hero-image {
  flex-basis: 45%;
  margin-bottom: 2rem;
}

.hero-image img {
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .hero {
    padding: 0rem 0.5rem;
    margin: 0;
  }

  .hero-container {
    padding: 0rem 0.5rem; 
  }

  .hero-text {
    margin: auto;
    margin-bottom: 2rem;
  }

  .hero-content {
    flex-basis: 100%;
    text-align: center;
  }

  .hero-image {
    flex-basis: 100%;
    text-align: center;
  }
  
}


/* --------------------------- */

/* ------ FEATURES -------- */

.features-section {
  background-color: #f5f5f5;
  padding: 4rem 0rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.features-container {
  max-width: 1400px;
  padding: 0rem 3rem; 

  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.features-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  
  width: 100%;
  padding: 0rem 1rem;
}

.feature {
  flex: 1;
  margin: 1.5%;
  padding: 1rem 1.5rem;
  text-align: center;
  background-color: rgb(253,253,251);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #333;
}

.feature-icon img {
  width: 60%;
  padding: 1rem;
}

.feature-header {
  font-size: 1.3rem; /* 1.5?? */
  margin-bottom: 1rem;
  color: #333;
}

.feature-text {
  color: #4e4e4e;
  line-height: 1.8;
  font-size: 1.1rem;
}

@media only screen and (max-width: 850px) {
  .features-container {
    flex-flow: column wrap;
    padding: 0.5rem 1rem;
  }

  .feature {
    width: 100%;
    margin: 1.5% 0;

    flex-direction: column;
  }
}


/* --------------------- */

/* --------- REASONS ------------ */

.reasons-section {
  background-color: rgb(253,253,251);
  margin: 2rem;
  padding: 0rem 0rem;
}

.reasons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.reasons-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  
  margin-top: 4rem;
  margin-bottom: 2rem;

  width: 100%;
}

.reasons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.reason {
  width: calc(60% - 25px);
  margin: 10px;
}

.reason-side {
  width: calc(40% - 25px);
  margin: 10px;
}

.reason-image img {
  width: 100%;
  display: block;
  object-fit: cover;
  margin: auto;
}

.reason-text {
  padding: 1.2rem 0.5rem;
  background-color: #f2f2f2;

  border-radius: 5px;
  margin-bottom: 1rem;
}

.reason-title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;

  padding: 0rem 1rem;
}

.reason-title img {
  width: 4rem;
}

.reason-title span {
  margin-left: 1rem;
}

.reason-description {
  margin: 0.8rem 0 0 0;
  line-height: 1.5;
  color: #666;
  padding: 0 1rem;
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .reason {
    flex-basis: 100%;
  }

  .reasons-section {
    margin: 0rem;
    padding: 0rem 1rem;
  }

  .reason-side {
    flex-basis: 100%;
  }

  .reason-text {
    margin-right: 0;
  }

  .reason-image {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
}



/* ------- PRICING -------- */

.pricing-section {
  background-color: #f5f5f5;
  padding: 4rem 0;

}

.pricing-container {
  max-width: 1400px;
  padding: 0rem 4rem; 

  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pricing-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;

  width: 100%;
  padding: 0rem 1rem;
}

.pricing-plan {
  width: calc(40% - 1.5%);
  margin: 1.5%;
  padding: 2rem;
  text-align: left;
  background-color: rgb(253,253,251);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.plan-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: black;
}

.plan-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.4em;
}

.plan-description.mva {
  margin-top: 1rem;
  margin-bottom: 0;
}

.plan-title-included {
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0rem;
  font-weight: bold;
  color: #484d5a;
}

.plan-cost {
  font-size: 2rem;
  margin-bottom: 0rem;
  color: #484d5a;
}

.plan-cost-period {
  font-size: 1.1rem;
  margin-bottom: 0rem;
  color: #484d5a;
}

.plan-details {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.plan-details li {
  margin-bottom: 0.2rem;
  color: #484d5a;

}

.start-now-button {
  background-color: rgb(255, 120, 1);
  color: rgb(253,253,251);
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 0.3em;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration:none;

  border-bottom: 0.18em solid #1ba0f220;
  border-bottom-style: groove;
  transition: all 0.3s ease-in-out;
}

.start-now-button:hover {
  font-size: 1.3rem;
}

@media only screen and (max-width: 1200px) {
  .pricing-plan {
    width: 100%;
  }

  .pricing-container {
    padding: 0rem 1rem;
  }

  .pricing-title {
    text-align: center;
  }
}

/* ---------------------------- */

/* ------------ TESTIMONIALS ------------- */

.testimonials-section {
  background-color: rgb(253,253,251);
  padding: 4rem 0;
}

.testimonials-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  
  width: 100%;
  padding: 0rem 1rem;
}

.testimonials-container {
  max-width: 1400px;
  padding: 0rem 4rem; 

  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial {
  width: 40%;
  margin: 1.5%;
  padding: 2rem;
  text-align: center;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
}

.testimonial-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -25px;
  margin-left: -25px;
}

.testimonial-text {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.testimonial-name {
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0rem;
  text-align: left;
  font-weight: bold;
  color: rgb(255, 120, 1);
}

@media only screen and (max-width: 767px) {
  .testimonial {
    width: 100%;
    margin-bottom: 3rem;
    padding: 2rem 0rem;
  }

  .testimonial-name {
    padding: 0rem 1rem;
  }

  .testimonials-container {
    padding: 0rem 2rem;
  }

  .testimonial-text {
    padding: 0rem 2rem;
  }
}


/* --------------------------- */

/* ----------- TRUSTPILOT ----------- */

.trustpilot-widget {
  background-color: rgb(253,253,251);
}

/* --------------------------- */


/* ----------- Q&A ---------------- */

.qa-section {
  background-color: rgb(253,253,251);
  padding: 4rem 0;
}

.qa-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  
  width: 100%;
  padding: 0rem 1rem;
}

.qa-container {
  max-width: 1400px;
  padding: 0rem 4rem; 

  margin: 0 auto;
}

.qa {
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;

  user-select: none;
}

.qa:hover, .qa h3:hover, .qa p:hover {
  cursor: pointer;
}

.qa-question {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
  margin: auto;
  padding: 0.3rem;
}

.qa-answer {
  display: none;
  font-size: 1rem;
  line-height: 1.8;
  color: #666;
}

.qa-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
  outline: none;
}

.qa-button:focus {
  outline: none;
}

@media only screen and (max-width: 767px) {
  .qa-question {
    font-size: 1.1rem;
  }

  .qa-container {
    padding: 2rem;
  }
}


/* ---------------------------------- */

/* ---------- ARTICLES --------------- */


.article-section {
  background-color: rgb(253,253,251);
  padding: 0rem 0;
  padding-bottom: 2rem;
  text-align: center;
}

.article-container {
  max-width: 1400px;
  padding: 0rem 0rem; 
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.article-preview {
  margin-bottom: 2rem;
  transition: all 0.3s ease-in-out;
}


.section-heading {
  font-size: 2rem;
  margin-bottom: 3rem;
  text-align: center;
}

.article-preview a {
  display: block;
  text-decoration: none;
  color: #333;
}

.article-preview img {
  max-width: 100%;
  margin-bottom: 1rem;
  border: 5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.article-title {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;

  padding: 0rem 1rem;
}

.article-title:hover {
  cursor: pointer;
}

.article-preview:hover {
  cursor: pointer;
  font-size: 1.3rem;
  transform: scale(1.05);
}

@media only screen and (min-width: 768px) {
  .article-preview {
    width: 45%;
    float: left;
    padding: 0 1rem;
  }

  .article-preview:nth-child(odd) {
    clear: left;
  }
}

@media only screen and (max-width: 768px) {
  .article-preview {
    width: 100%;
    float: center;
    padding: 0 1rem;
  }

  .article-container {
    padding: 0rem 1rem; 
  }

  .article-title {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
}



/* ------------------------------- */

/* ----------- FOOTER ------------ */

.footer {
  background-color: rgb(253,253,251);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
  padding: 1rem;
}

.footer-container {
  display: flex;
  flex-direction: column; /* change to column on mobile */
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  padding: 0rem 0rem; /* reduce the padding */

  margin: 0 auto;
}

.footer-copy {
  font-size: 0.8rem;
  text-align: center; /* center the text on mobile */
  width: 50%;
}

.footer-links-container {
  text-align: center; /* center the links on mobile */
  width: 50%
}

.footer-link {
  display: block; /* stack the links on mobile */
  margin-bottom: 0.5rem; /* add some bottom margin */
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.footer-business {
  text-decoration: none;
  color: #333;
}

.footer-business:hover {
  cursor: pointer;
}

.footer-copy:hover {
  cursor:default;
}

@media only screen and (min-width: 768px) {
  .footer-container {
    flex-direction: row; /* change back to row on larger screens */
    justify-content: center;
  }

  .footer-link {
    display: inline-block; /* switch back to inline-block on larger screens */
    margin: 0 1rem;
  }


}

@media only screen and (max-width: 768px) {
  .footer-links-container {
    width: 100%;

  }

  .footer-link {
    margin-bottom: 1rem;
  }

  .footer-copy {
    width: 100%;
  }

}

/* ------------------------------- */

/* ----------- ARTICLE / GUIDE ------------ */

.goback-button {
  background-color: rgb(255, 120, 1);
  color: rgb(253,253,251);
  padding: 0.7rem 1.2rem;
  border: none;
  border-radius: 0.3em;
  font-size: 1rem;
  cursor: pointer;

  border-bottom: 0.18em solid #1ba0f220;
  border-bottom-style: groove;
  transition: all 0.3s ease-in-out;
}

.goback-button:hover {
  font-size: 1.1rem;
}

.article {
  max-width: 1400px;
  text-align: left;
  margin: 3rem auto;
  padding: 0rem 4rem;
  line-height: 1.5rem;
}

.guide {
  max-width: 1400px;
  text-align: left;
  line-height: 1.5rem;
  margin: 3rem auto;
}

.article p, .guide p {
  margin-top: 1.5rem;
}

.article h1, .guide h1 {
  line-height: 2.5rem;
}

.guide h1 {
  line-height: 2.5rem;
  margin-top: 2em;
}

.article h2 {
  margin-top: 4rem;
}

.guide h2 {
  color: rgb(255, 120, 1);
}

.guide .flex-image-text {
  padding: 2em;
  max-width: 100%; 
  display: flex;
}

.content {
  flex: 1;
}

.guide .flex-image-text img {
  max-width: 25em;
  max-height: 25em; 
}

.greyBg {
  background-color: rgba(128, 128, 128, 0.150);
}

.lightGreyBg {
  background-color: rgba(128, 128, 128, 0.080);
}

@media only screen and (max-width: 768px) {
  .article {
    margin: 1rem;
    padding: 0.5rem 1rem;
  }  
  
  .guide {
    margin: 0rem;
    padding: 1rem 0rem;
  }

  .guide .flex-image-text img {
    margin-top: 1em;
  }

  .userInfo {
    margin: 1.5em;
  }

  .guide h1, .guide button {
    margin: 1em;
  }

  .article h1, .guide h1 {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

}

.references p, h3 {
  margin-top: 0rem;
}

.references a {
  color: black;
  text-decoration-line:underline;
  text-decoration-color: rgba(128, 128, 128, 0.4);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.quote {
  font-style: italic;
  color: #333;
  background-color: #f5f5f5;
  border-left: 4px solid #333;
  padding: 10px;
  margin: 10px 0;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-top: 1em;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border: 0.3em solid rgb(255, 120, 1);
  border-radius: 0.3em;
}

